.ReactModalPortal {
  position: relative;
  z-index: 99;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 'auto';
  bottom: 'auto';
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: $hh-white;
  padding: 30px;
  border-radius: $border-radius;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($hh-gray, 0.6);
}
