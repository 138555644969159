input[type='text'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='date'],
textarea,
.field,
.react-select .react-select__control {
  border: 1px solid $hh-gray;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}

.form-group,
.form-inline {
  input[type='number']:invalid {
    border: 1px solid $hh-red;
    box-shadow: 0 0 2px 0 $hh-red;
  }
}

.form-control {
  &.stripped {
    background: transparent;
    box-shadow: none;
    border: 0;
    margin: 0;
  }
}

select {
  background-color: $hh-green;
  color: white;
  border: 1px solid $hh-gray;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;

  &#property-sort,
  &#add_bedrooms {
    height: 36px;
    min-width: 200px;
    background-color: white;
    color: $hh-black;
    font-weight: bold;
    border: 1px solid $hh-gray;
  }
}

.has-error input {
  border: 1px solid $hh-red;
  box-shadow: none;
}

.form-inline {
  .form-group.organization_city,
  .form-group.organization_phone_number,
  .form-group.organization_state,
  .form-group.property_city,
  .form-group.property_state {
    flex-direction: column;
    align-items: flex-start;
  }

  &.city-state {
    display: flex;

    .organization_city,
    .property_city {
      flex: 1 1 0;
    }

    select {
      &#organization_state,
      &#property_state {
        width: 100px;
        height: 38px;
      }
    }

    input {
      &#organization_city,
      &#property_city {
        width: 100%;
      }
    }
  }
}

.role_selector {
  color: $hh-green;
  font-weight: bold;
}

.row-border {
  border-bottom: 1px solid #d5d5d5;
}

.card-field {
  &.card-body {
    display: flex;
    flex-direction: column;
  }

  .card-input {
    background: $hh-card-block-gray;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card-title {
    margin: rem(12px 0);
  }

  .lg {
    @extend h2;
  }

  textarea {
    resize: none;
  }
}

.btn-group-custom {
  &.active {
    color: $white;
    background: $hh-blue;
  }
}

.col-form-label {
  @extend h5;
}

.custom-control {
  display: flex;
  align-items: center;
}

.input-group-text {
  background-color: $white;
  border: 0;
}

.form-check-inline {
  label {
    flex-basis: 100%;
  }
}

.location-lookup-results {
  position: absolute;
  z-index: 2;
  width: 100%;

  .list-group-item {
    cursor: pointer;
  }
}

.input-group {
  > .form-control,
  > .custom-select {
    &:not(:last-child) {
      @include border-radius($input-border-radius);
    }
    &:not(:first-child) {
      @include border-radius($input-border-radius);
    }
  }
}

#location-lookup ~ .input-group-append {
  position: absolute;
  right: rem(4px);
  top: rem(5px);
  bottom: rem(4px);
  z-index: 100;
}

.custom-control-label::before {
  background-color: $white;
  border: 1px solid $hh-gray;
  box-sizing: content-box;
  top: rem(2px);
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%230DA45D'/%3E%3C/svg%3E");
  top: rem(3px);
  left: rem(-20px);
  background-size: 75%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $white;
}

.react-select {
  .react-select__control {
    background-color: transparent;
    min-height: 33px;
  }
  input[type='text'] {
    box-shadow: none;
  }

  .react-select__dropdown-indicator {
    padding: 6px;
  }
  
  .react-select__menu {
    z-index: 1000; // A really high number so it's always in front
  }
}

.intake-form-row {
  display: flex;
  margin-right: 15px;
  margin-left: -15px;
}

.form-invalid-msg {
  color: $hh-red;
}
