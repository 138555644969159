* {
  font-family: 'proxima-nova';
}

html {
  @include rem-baseline;
}

body {
  background-color: $hh-white;
  -webkit-font-smoothing: antialiased;
  color: $hh-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1 {
  font-size: rem(36px);
}
h2 {
  font-size: rem(28px);
}
h3 {
  font-size: rem(24px);
}
h4 {
  font-size: rem(20px);
}
h5 {
  font-size: rem(16px);
}

a {
  color: $hh-blue;
}

.two-column {
  @include media-breakpoint-up(md) {
    display: flex;
    flex-flow: wrap;

    > div {
      break-inside: avoid-column;
      flex: 0 0 50%;
    }

    hr {
      flex: 0 0 100%;
    }
  }
}

.sticky {
  position: sticky;
  top: rem(14px);
}

// Text Color

.green-text {
  color: $hh-green;
}

.blue-text {
  color: $hh-blue;
}

.black-text {
  color: $hh-black;
}

.red-text {
  color: $hh-red;
}

.gray-text {
  color: $hh-gray;
}

.white-text {
  color: $hh-white;
}

.light-gray-text {
  color: $hh-light-gray;
}
// Buttons ----------

a,
button,
input {
  &.btn {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-weight: bold;

    &:hover {
      color: white;
      background: $hh-blue;
      transition: 0.3s ease-in;
    }
  }
}

.btn-green {
  background-color: $hh-green;
  color: white;
}

.btn-red {
  background-color: $hh-red;
  color: white;
}

.btn-blue {
  background-color: $hh-blue;
  color: white;
}

.btn.btn-x {
  background-color: white;
  padding: 0;
  width: 28px;
  height: 28px;
  box-shadow: none;
  border: 1px solid $hh-gray;
  border-radius: 50%;
  opacity: 1;
  color: $hh-red;

  span {
    font-size: 30px;
    line-height: 30px;
  }

  &:hover {
    background-color: $hh-gray;
    opacity: 1;
    color: $hh-red;
    text-shadow: none;
    transition: background-color 0.3s ease-in;
  }
}

.btn.min-btn {
  color: $hh-black;
  background: white;
  border: $grey-border;

  &:hover {
    background: $hh-blue;
    transition: 0.3s ease-in;
    border: $clear-border;
  }
}

.back-to-hub-btn {
  color: $hh-black;
  background-color: white;
  border: $grey-border;
  border-radius: 6px;
  margin-top: rem(-7px);
}

.btn-icon {
  background: transparent;
  border: 0;
  cursor: pointer;
}

// Badges ---------

.badge {
  padding: 5px 10px;
  font-size: 100%;
}

.left-badge {
  display: inline;
  background-color: $hh-green;
  border-radius: 6px 0 0 6px;
  border: $clear-border;
  color: white;
}

.right-badge {
  display: inline;
  border: $grey-border;
  border-radius: 0 6px 6px 0;
  margin-left: -1px;
  color: black;
}

.badge-green {
  background-color: $hh-green;
  color: $hh-white;
  padding: 0.5rem;
  border: $clear-border;
  box-shadow: $shadow;

  &:hover {
    background: rgba($color: $hh-white, $alpha: 0.75);
    box-shadow: none;
    color: $hh-black;
    transition: 0.3s ease-in;
    border: $grey-border;
  }
}

// Cards ----------

.card-shadow {
  box-shadow: 0 12px 10px -10px rgba(0, 0, 0, 0.14);
}

.card {
  border-radius: rem(10px);

  .card-heading {
    text-align: center;
  }

  .form-actions {
    display: flex;
    justify-content: center;
  }
}

.help-block {
  font-size: 14px;
  color: $hh-red;
}

// Utils ----------

.gray-card-bg {
  background-color: $hh-card-block-gray;
}

.white-bg {
  background-color: white;
}

.line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.blue {
  background: $hh-blue;
}

.green {
  background: $hh-green;
}

.black {
  background: $hh-black;
}

.icon {
  height: 26px;
  width: 26px;
}

.popover-header {
  color: $hh-green;
}

.page-wrap {
  margin-top: 2rem;
}

// Icons

.green-icon {
  display: inline-block;
  border: transparent;
  background-color: $hh-green;
  box-shadow: $shadow;
  padding: 7px;
  margin: 0 2px;
  border-radius: 3px;
}

.blue-icon {
  display: inline-block;
  border: transparent;
  background-color: $hh-blue;
  box-shadow: $shadow;
  padding: 7px;
  border-radius: 3px;
}

.green-icon {
  background-color: $hh-green;
}

.red-icon {
  background-color: $hh-red;
}

.black-icon {
  background-color: $hh-black;
}
// Hovers
.hover-dark:hover {
  color: black;
}

// Empty state

.empty-state-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  border: 1px dashed $hh-gray;
  background-color: $hh-empty-state-fill;
  padding: rem(50px);
}

.intake-header {
  background-color: $hh-light-blue-v2;
  color: $hh-dark-blue-v2;
  h2 {
    color: $hh-green;
    font-size: rem(20px);
  }
}

// waitlists

.waitlist-result-buttons {
  @include media-breakpoint-down(md) {
    button {
      margin-bottom: 1rem;
    }
  }
}

.waitlist-position {
  margin-top: -1.5rem;
  background-color: #f0fff8;
  border: 1px solid $hh-green;
  &.removed {
    background-color: #fffdea;
    border: 1px solid $hh-gray;
  }
}

.waitlist-card {
  z-index: 2;
}

.property-image-wrapper {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 162px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.property-image-bg {
  background-color: white;
  border: 1px solid $hh-light-gray;

  .property-image-wrapper {
    height: 117px;
  }

  &.featured-image .property-image-wrapper {
    height: 252px;
  }
}

.download-link {
  cursor: pointer;

  .fa-spinner {
    display: inline-block;
    margin-left: 8px;
    color: $hh-blue;
  }
}

button.red-link {
  color: $hh-red;
  padding: 0;
  border: none;
  background: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.document-upload-dropzone {
  border: 2px dashed $hh-gray;
}

.footer {
  background: $hh-dark-blue-v2;
  margin-top: 100px;

  a {
    color: $hh-light-blue-v2;
  }

  .icon {
    height: 20px;
  }
}

h3 > * {
  vertical-align: middle;
}

.loader-container {
  display: inline-block;
  margin-right: 12px;
  margin-top: -2px;
}

.empty-state-container ~ .empty-state-container,
.empty-state-container ~ .mb-5 {
  margin-top: 18px;
}
