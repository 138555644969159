.navbar {
  background: white;
  box-shadow: $shadow;
  font-size: rem(16px);
  height: $navbar-height-desktop;
  box-sizing: content-box;

  .brand {
    width: 250px;
  }

  .nav-link {
    font-weight: bold;
    color: $hh-black;
  }

  &.redesigned {
    background: $hh-light-blue-v2;
    font-size: 18px;
  }
}

.navbar-nav {
  flex-direction: row;
}

@include media-breakpoint-down(sm) {
  .navbar-nav {
    flex: 1 1 0;
    flex-direction: column;

    .nav-item {
      display: block;
      width: 100%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar {
    height: unset;
  }
}

// Profile Nav ---------

.profile-image {
  height: 150px;
}

.profile-links {
  display: flex;
  flex-direction: column;

  a {
    color: black;
    padding-bottom: rem(10px);
    margin-bottom: rem(10px);
    border-bottom: 1px solid $hh-light-gray;
  }

  .active {
    font-weight: bold;
  }
}
