// STOP!
// Are you adding something that should be in the Admin application as well?
// Shared assets are managed here: https://github.com/softwareforgood/housing_hub_assets
// Update that gem, then re-run `yarn add github:softwareforgood/housing_hub_assets` to
// get the latest.

// TODO: figure out why housing_hub_assets
// looks inconsistent between Applicant app and Rails app
// Add these colors here for now
$hh-light-blue-v2: #cdf1f9;
$hh-dark-blue-v2: #06596c;
$hh-blue-v2: #08a7cc;
