// originally from https://github.com/softwareforgood/housing_hub_assets
@use 'sass:math';

// Colors -----------
$hh-green: #0a854b;
$hh-blue: #01819d;
$hh-black: #4a4a4a;
$hh-red: #d0021b;
$hh-gray: #979797;
$hh-white: #f5f9fa;
$hh-light-gray: #d5d5d5;
$hh-card-block-gray: #f8f8f8;
$hh-empty-state-fill: #e8f0f2;
$hh-pdf-red: linear-gradient(180deg, #ea053b 0%, #d0021b 100%);

// Borders ----------
$grey-border: 1px solid #c2c2c2;
$clear-border: 1px solid transparent;

// Shadows ----------
$shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);

// Bootstrap Overrides -----------
$alert-border-radius: 0;
$green: $hh-green;
$alert-bg-level: 0;

$navbar-height-desktop: 86px;

// Mixins -----------
// PX to REM - https://github.com/pierreburel/sass-rem
$rem-baseline: 14px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;
@function rem-separator($list, $separator: false) {
  @if $separator == 'comma' or $separator == 'space' {
    @return append($list, null, $separator);
  }
  @if function-exists('list-separator') == true {
    @return list-separator($list);
  }
  // list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }
  @return if($test-list == $list, space, comma);
}
@mixin rem-baseline($zoom: 100%) {
  font-size: math.div($zoom, 16px) * $rem-baseline;
}
@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);
  @each $value in $values {
    @if type-of($value) == 'number' and unit($value) == 'rem' and $to == 'px' {
      $result: append(
        $result,
        math.div($value, 1rem) * $rem-baseline + 0px,
        $separator
      );
    } @else if
      type-of($value) ==
      'number' and
      unit($value) ==
      'px' and
      $to ==
      'rem'
    {
      $result: append(
        $result,
        math.div($value, $rem-baseline) + 0rem,
        $separator
      );
    } @else if type-of($value) == 'list' {
      $value-separator: rem-separator($value);
      $value: rem-convert($to, $value...);
      $value: rem-separator($value, $value-separator);
      $result: append($result, $value, $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }
  @return if(length($result) == 1, nth($result, 1), $result);
}
@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}
@mixin rem($properties, $values...) {
  @if type-of($properties) == 'map' {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}
// End PX to REM
