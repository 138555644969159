.popover-container {
  position: relative;
}

.popover {
  display: none;
  left: 100%;
  font-size: $font-size-base;

  &.show {
    display: block;
  }
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^='right'] .arrow::after {
  border-right-color: $popover-header-bg;
}
