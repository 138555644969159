.alert {
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 10;

  .close {
    color: $white;
    font-size: rem(30px);
    font-weight: 300;
    line-height: 0.75;
    opacity: 1;
  }
}

// TODO: resolve duplication in common.scss
.alert.fadeout {
  border-radius: 0;
  text-align: center;
  color: white;
  font-weight: bold;

  button.close {
    color: white;
    opacity: 1;
  }

  &.alert-alert {
    background-color: $hh-blue;
  }

  &.alert-error {
    background-color: $hh-red;
  }

  &.alert-notice,
  &.alert-success {
    background-color: $hh-green;
  }
}

.alert-light {
  &.alert-warning {
    background-color: lighten($warning, 40%);
    border-color: darken($warning, 20%);
  }
}
